<template>
  <v-card>
   <v-toolbar
					dense
					color="primary"
					height="40"
					dark
					label="Nuevo Contrato">
					<h3>Creación de Contrato</h3>
					<v-spacer></v-spacer>
          <div >
						<v-btn
							fab
							class="elevation-0 mr-3"
							small
							color="primary"
							dark
							@click="saveOverall()"
							><v-icon>mdi-content-save</v-icon></v-btn
						>
					</div>
          <v-btn
						fab
						class="elevation-0"
						small
						color="primary"
						dark
						@click="close()"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
        <v-row>
					<v-col cols="12" sm="12" lg="12">
            <v-row>
              <v-col cols="12" sm="12" lg="12">
              
                  <!-- Definir las pestañas -->
                  <v-tabs v-model="currentTab" centered>                   
                    <v-tab  href="#DataLabor"   >Datos Laborales</v-tab>
                    <v-tab  href="#DataInsured" >Datos Asegurados</v-tab>
                    <v-tab  href="#DataBancking">Datos Bancarios</v-tab>
                  </v-tabs>

                  <!-- Contenido de las pestañas -->
                  <keep-alive>
                  <v-tabs-items v-model="currentTab" >
                    <!-- Contenido para la pestaña de "Datos Laborales" -->
                    <v-tab-item :value="'DataLabor'" >
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="12" lg="9" sm="12">
                            <s-data-labor ref="sDataLabor" :full="true" :visibleTitle="false"  :datawork="dataWork" @updateContractsPast="handleContractsPast" />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <!-- Contenido para la pestaña de "Datos Asegurados" -->
                    <v-tab-item :value="'DataInsured'" v-if="!contractsPast">
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-Asegurado ref="sDataAsegurado" :full="true" :visibleTitle="false"  :datawork="dataWork"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'DataInsured'" v-if="contractsPast">
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-insured-edit ref="sDataAsegurado" :full="true" :visibleTitle="false" :datawork="contractsPast"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <!-- Contenido para la pestaña de "Datos Bancarios" -->
                    <v-tab-item :value="'DataBancking'" v-if="!contractsPast">
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-Bancarios ref="sDataBancarios" :full="true" :visibleTitle="false"  :datawork="dataWork"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'DataBancking'" v-if="contractsPast">
                      <v-card class="pa-4">
                        <v-row justify="center">
                          <v-col md="9" lg="9" sm="12">
                            <s-data-bancking-edit ref="sDataBancarios" :full="true" :visibleTitle="false"  :datawork="contractsPast"/>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  </keep-alive>
                  <v-card-actions>
                
                  </v-card-actions>
              
              </v-col>
              <v-dialog v-if="dialogEditPerson" v-model="dialogEditPerson"  width="80%" style=" height:90%;" >
                <contract-person-add
                  ref="textAddPerson"
                  @personSaved="personSaved($event)"
                  @toclose="closeDialog()"
                
                />
              </v-dialog>
            </v-row>
          </v-col>
       </v-row>

      <PdfContractPorTemporada 
        ref="pdfContractPorTemporada"
        :data="PdfSelectedContract" 
        :preventIndividualDownload="preventIndividualDownload = true"
        @close="dialogPdfContractPorTemporada = false"   
        v-if="dialogPdfContractPorTemporada" 
        v-model="dialogPdfContractPorTemporada"
        style="visibility: hidden;">
      </PdfContractPorTemporada>

      
      <PdfContractPorIncrementoActividades 
        ref="PdfContractPorIncrementoActividades"
        :data="PdfSelectedContract" 
        :preventIndividualDownload="preventIndividualDownload = true"
        @close="dialogPdfContractPorIncrementoActividades = false"
        v-if="dialogPdfContractPorIncrementoActividades" 
        v-model="dialogPdfContractPorIncrementoActividades"
        style="visibility: hidden;">
      </PdfContractPorIncrementoActividades>


      <PdfContractPorServicioEspecifico 
        ref="PdfContractPorServicioEspecifico"
        :data="PdfSelectedContract" 
        :preventIndividualDownload="preventIndividualDownload = true"
        @close="dialogPdfContractPorServicioEspecifico = false"
        v-if="dialogPdfContractPorServicioEspecifico" 
        v-model="dialogPdfContractPorServicioEspecifico"
        style="visibility: hidden;">
      </PdfContractPorServicioEspecifico>

      <pdf-critical-print
          ref="pdfCriticalPrint"
          :itemSelect="itemsSearch"
          :preventIndividualDownload="preventIndividualDownload = true"
          @close="openPdfCritical = false"
          v-if="openPdfCritical" 
          v-model="openPdfCritical"
          style="visibility: hidden;">
      </pdf-critical-print>

      <pdf-sst-critical
        ref="pdfSSTCritical"
        :items="itemsSSTCritical"
        :itemsst="itemsst"
        :preventIndividualDownload="preventIndividualDownload = true"
        :fingerprintImage="fingerprintImage"
        @close="openPdfSSTCritical = false"
        v-if="openPdfSSTCritical" 
        v-model="openPdfSSTCritical"
        style="visibility: hidden;">
      </pdf-sst-critical>

      <v-dialog
        v-if="dialogPdfContractMasivo"
        v-model="dialogPdfContractMasivo"
        fullscreen
        persistent
      >
        <PdfContractMasivo
          :dataMasiva="PdfSelectedContract"
          @close="dialogPdfContractMasivo = false"
        ></PdfContractMasivo>
      </v-dialog>

      <v-dialog v-model="DialogDocument" v-if="PdfSelectedContract" persistent width="500">
        <s-toolbar
          label="Documentos"
          close
          @close="closeModalDocument()"
          color="#BAB6B5"
          dark
        ></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <h2>{{ this.PdfSelectedContract[0].NtpFullName }} - {{ this.PdfSelectedContract[0].PrsDocumentNumber }}</h2>
              </v-col>
              <v-col cols="12" class="text-left">
                <h5>Seleccione los documentos a descargar</h5>
              </v-col>
            </v-row>
            <!-- Lista de opciones -->
            <v-row>
              <v-col cols="12" class="s-col-form">
                <v-list dense>
                  <v-list-item 
                    v-for="item in filteredDocumentOptions" 
                    :key="item.value" 
                    :class="{'selected-item': selectedDocuments.includes(item.value)}"
                    @click="toggleSelection(item.value)"
                    class="custom-item"
                  >
                    <v-list-item-action>
                      <v-checkbox 
                        :input-value="selectedDocuments.includes(item.value)"
                        @click.stop="toggleSelection(item.value)"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="mergeAndDownloadPDFs()">
                  <v-icon left>mdi-download</v-icon>
                  Descargar Seleccionados
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="dialogloading"
        max-width="250"
        persistent
      >
        <v-card>
          <v-card-text class="py-3 px-3">
            <div class="d-flex align-center">
              <v-icon color="primary" class="mr-3">mdi-file-download-outline</v-icon>
              <span class="text-body-2 flex-grow-1">Descargando...</span>
              <v-progress-circular
                indeterminate
                color="primary"
                :size="14"
                :width="2"
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <AlertSunshine :dialog="dialog" />
	</v-card>
  
</template>

<script>
import ContractPersonAdd from "@/views/HumanResource/AgreementPersonAdd.vue";
import sAddPerson from "@/components/Utils/Person/sAddPerson.vue";
import sDataLabor from "@/components/Utils/RecursosHm/sDatosLaborals";
import sDataAsegurado from "@/components/Utils/RecursosHm/sDatosAsegurados";
import sDataBancarios from "@/components/Utils/RecursosHm/sDatosBancarios";
import _sCttoService from '@/services/HumanResource/HmnContractService.js';
import _service from "@/services/HelperService";
import _sWorkertService from '@/services/HumanResource/WorkerService.js';
import PdfContractPorTemporada from './PdfContract/PdfContractPorTemporada.vue';
import PdfContractPorIncrementoActividades from './PdfContract/PdfContractPorIncrementoActividades.vue';
import PdfContractPorServicioEspecifico from './PdfContract/PdfContractPorServicioEspecifico.vue';
import sDataInsuredEdit from "@/components/Utils/RecursosHm/sDataInsuredEdit";
import sDataBanckingEdit from "@/components/Utils/RecursosHm/sDataBanckingEdit";
import { provide  } from 'vue';
import _sWorkerTabService from '@/services/HumanResource/HmnWorkerTabService.js';
import PdfCriticalPrint from './ContractDocument/HmnPrintCritical.vue';
import PdfSstCritical from './ContractDocument/HmnPrintSSTCritical.vue';
import _sDangerSSTService from "@/services/HumanResource/HmnDangerSSTService";
import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";
import { PDFDocument } from 'pdf-lib';
import PdfContractMasivo from "@/views/HumanResource/PdfContract/PdfContractMasivo.vue";
import _sGenParam from "../../services/General/ParameterService";

export default {
  components: { sAddPerson,sDataLabor,sDataAsegurado,sDataBancarios, ContractPersonAdd, PdfContractPorTemporada, 
    PdfContractPorIncrementoActividades, PdfContractPorServicioEspecifico ,
    sDataInsuredEdit,sDataBanckingEdit, sFingerPrint, PdfCriticalPrint, PdfSstCritical, PdfContractMasivo
  },
  props: {
    objEdit: { type: Object },
  },
  data() {
    return {
      dni: '',
      dnii:"",
      currentTab: 0,
      person: null,
      image: null,
      isCameraActive: false, // Para saber si la cámara está activa
      dataTableTransport: [],
      item: {},
      objLot: {},
      dataWork: {},
      dataPers: {},
      dialogEditPerson:false,
      workert:null,
      dialogPdfContractPorTemporada: false,
			dialogPdfContractPorIncrementoActividades: false,
			dialogPdfContractPorServicioEspecifico: false,
      contractsPast:null,
      dates: {  // Declarar como un objeto normal
        AgtAdmissionDate: null,
        AgtEndDate: null
      },
      DialogDocument : false,
      PdfSelectedContract: null,
      itemsSearch: [],
      openPdfCritical : false,
      itemsSSTCritical: [],
      fingerprintImage : null,
      itemsst: [],
      openPdfSSTCritical: false,
      selectedDocuments: [],
      preventIndividualDownload: false,
      dialogPdfContractMasivo: false,
      dialog: false,
      documentOptions: [
        { text: "Contrato", value: "contract" },
        { text: "Designación del puesto Crítico", value: "critical" },
        { text: "Recomendaciones SST", value: "sst" },
      ],
      dialogloading: false
    };
  },
  computed: {
    filteredDocumentOptions() {
      let options = [...this.documentOptions];

      // Si el valor de PstIsBascCritic es menor que 3, eliminamos "critical"
      if (this.PdfSelectedContract[0].PstIsBascCritic < 3) {
        options = options.filter(item => item.value !== 'critical');
      }

      return options;
    }
  },
  methods: {
    toggleSelection(value) {
      const index = this.selectedDocuments.indexOf(value);
      if (index === -1) {
        this.selectedDocuments.push(value);
      } else {
        this.selectedDocuments.splice(index, 1);
      }
    },
    initialize() {
      if (this.objEdit && typeof this.objEdit === 'object') {
          console.log("Objeto Edit", this.objEdit);
        _sCttoService.workerPerson(this.objEdit.WkrID, this.$fun.getUserID()).then((r) => { 
          if (r.status == 200) {
            console.log("dataWork: ",r.data);
            this.dataWork=r.data;
            // ServicePer.getNaturalPerson(this.item.DNI, this.$fun.getUserID()).then((r) => {
            //   if (r.status == 200) {
            //     console.log("dataPers: ",r.data);
            //     this.dataPers=r.data;
            //     console.log("datageneralp: ",this.dataPers);
            //     console.log("datageneralw: ",this.dataWork);
            //   }
            // });
          }
        });
      }
		},
    handleContractsPast(contracts) {
      //console.log("Recibiendo contratos pasados en Contract.vue:", contracts);
      this.contractsPast = contracts;
    },
    workertSearchByWkrID(wokert){
      this.workert = wokert;
      
    },
    search(value) {
      if (value != null) {
        if (value.GenPerson.PrsDocumentNumber.length > 0)
          _service.getPhotoWorker(value.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((rsp) => {
            const blob = new Blob([rsp.data], { type: "image/jpg" });
            this.dnii=value.GenPerson.PrsDocumentNumber;
            let image = blob;
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              this.image = reader.result;
            };
          });
        else this.image = null;
      } else {
        this.image = null;
      }
    },
    save() {
      this.image = null;
    },
    async saveOverall() {
      //const datosPersonales = this.$refs.sAddPerson.getData();  
      let datosLaborales  = this.$refs.sDataLabor?.getData(); 
      let datosAsegurados = this.$refs.sDataAsegurado?.getData();  
      let datosBancarios  = this.$refs.sDataBancarios?.getData();       

      if (!datosLaborales ) {
        //this.$fun.alert("Faltan datos por completar", "warning");
        console.log("Faltan datos por completar11111");
        return;
      }
      
      console.log("WORKERT",datosLaborales.workert);

      let worker = {
        WkrID       : datosLaborales.workert ? datosLaborales.workert.WkrID       : 0,
        WkrScireID  : datosLaborales.workert ? datosLaborales.workert.WkrScireID  : 0,
        WkrStatus   : datosLaborales.workert ? datosLaborales.workert.WkrStatus   : 1,
        NtpID       : datosLaborales.NtpID,
        Agreement:[ 
          { 
            ...datosLaborales,
            AgreementMovemts:[
              {
                ...datosLaborales,
                AmsChildrenQuantity   : datosLaborales.AgtChildrenQuantity,
                TypeAms               : 3,//3-> Origen
                AmsBeginDate          : datosLaborales.AgtBeginDate,
                AmsEndDate            : datosLaborales.AgtEndDate,
                AmsTerminationDate    : datosLaborales.AgtTerminationDate,
                AmsMaximunDay         : datosLaborales.AgtMaximunDay,
                AmsSalary             : datosLaborales.AgtSalary,
                AmsAdmissionDate      : datosLaborales.AgtAdmissionDate,
                AmsStatus             : 1,
                AgtStatus             : datosLaborales.AgtStatus,
                AmsRenovation         : datosLaborales.AgtRenovation,
                AmsStartDateMovement  : datosLaborales.AgtBeginDate,
                AmsEndDateMovement    : datosLaborales.AgtEndDate,
                AmsMonthsProbation    : datosLaborales.AgtMonthsProbation,
                AmsServiceDescription : datosLaborales.AgtServiceDescription,
                UsrCreateID           : this.$fun.getUserID(),
                //UsrUpdateID:this.$fun.getUserID(),
              }
            ]        
          }
        ],
        Health: datosAsegurados ? [
          { 
            ...datosAsegurados.HmnHealth
          }
        ] : null,
        Pension: datosAsegurados ? [
          { 
            ...datosAsegurados.HmnPension
          }
        ] : null,
        BankAccount   : datosBancarios ? datosBancarios : null,
        Supervision   : datosLaborales.Supervision ? datosLaborales.Supervision   : 1,
        Notification  : datosLaborales.workert ? datosLaborales.workert.Notification  : 1,
        UsrCreateID   : datosLaborales.workert ? datosLaborales.workert.UsrCreateID   : this.$fun.getUserID(),
        UsrUpdateID   : datosLaborales.workert ? this.$fun.getUserID()  : 0,
        SecStatus     : datosLaborales.workert ? datosLaborales.workert.SecStatus : 1,
      }    


      //console.log("Trabajador",worker);    
      
      this.$fun.alert('Seguro de Guardar?', 'question').then(async (resp) => {
        if (!resp.value) return; // Si el usuario cancela, no hace nada

        try {
          const response = await _sWorkertService.save(worker, this.$fun.getUserID());

          console.log("Data", response);

          if (response.status === 200) {
            this.$fun.alert("Registrado Correctamente", "success");

            // Guardar archivo si es necesario
            if (datosAsegurados?.HmnPension?.PsnAttach) {
              this.$refs.sDataAsegurado?.saveFile();
            }

            console.log("x.data:", response.data.requestID);

            // 🔹 Espera 500ms antes de mostrar la siguiente alerta para evitar que se sobrepongan
            setTimeout(async () => {
              // Pregunta si desea imprimir el contrato
              const imprimirContrato = await this.$fun.alert('¿Imprimir documentos del registro del trabajador?', 'question');
            
              if (imprimirContrato.value) {
                //console.log("Aqui va el Contrato de Trabajo");

                try {
                  const contractResponse = await _sCttoService.searchContract(response.data.requestID, this.$fun.getUserID());

                  if (contractResponse.status === 200) {
                    console.log("r.data:", contractResponse.data);
                    const item = contractResponse.data[0];

                    // Verifica si `itemsFunciones` es un string JSON válido
                    if (typeof item.itemsFunciones === 'string') {
                      try {
                        item.itemsFunciones = JSON.parse(item.itemsFunciones);
                      } catch (e) {
                        console.error("Error al parsear item.itemsFunciones:", e);
                      }
                    } else if (typeof item.itemsFunciones !== 'object' || item.itemsFunciones === null) {
                      console.error("item.itemsFunciones no es un JSON válido o no es una cadena JSON.");
                    }

                    // Asigna el contrato seleccionado
                    this.PdfSelectedContract = [item];
                    this.DialogDocument = true;
                    // Muestra el diálogo según el tipo de contrato
                    // switch (item.TypeContract) {
                    //   case 11:
                    //     this.dialogPdfContractPorTemporada = true;
                    //     break;
                    //   case 3:
                    //     this.dialogPdfContractPorIncrementoActividades = true;
                    //     break;
                    //   case 9:
                    //     this.dialogPdfContractPorServicioEspecifico = true;
                    //     break;
                    //   default:
                    //     console.warn("Tipo de contrato no reconocido:", item.TypeContract);
                    // }
                  }
                } catch (error) {
                  console.error("Error al buscar el contrato:", error);
                  this.$fun.alert("Ocurrió un error al buscar el contrato", "error");
                }
              }
              else{
                this.$fun.alert("Registrado Correctamente", "success");
                this.$emit("close");
              }
            }, 2000);
          }
        } catch (error) {
          console.error("Error al guardar el trabajador:", error);
          this.$fun.alert("Ocurrió un error al registrar el trabajador", "error");
        }
      });
      // this.$fun.alert('Seguro de Guardar?', 'question')
			// .then(resp => {
      //   if(resp.value){
      //     _sWorkertService.save(worker,this.$fun.getUserID()).then(x=>{
      //       console.log("Data",x);
      //       if(x.status == 200 ){             
      //         this.$fun.alert("Registrado Correctamente","success");
      //         if(datosAsegurados?.HmnPension?.PsnAttach){
      //           this.$refs.sDataAsegurado?.saveFile();
      //         }     
      //         console.log("x.data:",x.data.requestID)
      //         this.$fun.alert('¿Desea Imprimir el Contrato de Trabajo?', 'question')
      //         .then(resp => {
      //           if(resp.value){
      //             console.log("Aqui va el Contrato de Trabajo");
      //             _sCttoService.searchContract(x.data.requestID, this.$fun.getUserID())
      //             .then((r) => {
      //               if (r.status == 200) {
      //                 console.log("r.dataaaaaaaaaaaaa:", r.data);
      //                 const item = r.data[0];
      //                 if (typeof item.itemsFunciones === 'string') {
      //                   try {
      //                     item.itemsFunciones = JSON.parse(item.itemsFunciones);
      //                   } catch (e) {
      //                     console.error("Error al parsear item.itemsFunciones:", e);
      //                   }
      //                 } else if (typeof item.itemsFunciones !== 'object' || item.itemsFunciones === null) {
      //                   console.error("item.itemsFunciones no es un JSON válido o no es una cadena JSON.");
      //                 }
      //                 this.PdfSelectedContract = item;
      //                 if(item.TypeContract == 11){
      //                   this.dialogPdfContractPorTemporada = true;
      //                 }else if(item.TypeContract == 3){
      //                   this.dialogPdfContractPorIncrementoActividades = true;
      //                 }else if(item.TypeContract == 9){
      //                   this.dialogPdfContractPorServicioEspecifico = true;
      //                 }
      //               }
      //             })
      //           }
      //         });
      //       }           
      //     },
      //     (e) => {
      //       this.$fun.alert("Ocurrió un error-> " + e.response.data.Message, "error");
      //     }).catch((error) => {
      //       console.error("Error al guardar el trabajador:", error);
      //       //this.$fun.alert("Ocurrió un error al registrar", "error");
      //     });
      //   }
      // });
    },

    DownloadContractDocument() {
      switch (this.PdfSelectedContract[0].TypeContract) {
        case 11:
          this.dialogPdfContractPorTemporada = true;
          this.$nextTick(() => {
            this.$refs.pdfContractPorTemporada?.downloadPdf();

            setTimeout(() => {
              this.dialogPdfContractPorTemporada = false;
            }, 300);
          });
          break;
        case 3:
          this.dialogPdfContractPorIncrementoActividades = true;
          this.$nextTick(() => {
            this.$refs.PdfContractPorIncrementoActividades?.downloadPdf();

            setTimeout(() => {
              this.dialogPdfContractPorIncrementoActividades = false;
            }, 300);
          });
          break;
        case 9:
          this.dialogPdfContractPorServicioEspecifico = true;
          this.$nextTick(() => {
            this.$refs.PdfContractPorServicioEspecifico?.downloadPdf();

            setTimeout(() => {
              this.dialogPdfContractPorServicioEspecifico = false;
            }, 300);
          });
          break;
        default:
          console.warn("Tipo de contrato no reconocido:", this.PdfSelectedContract[0].TypeContract);
      }
    },
    DownloadCriticalDocument() {
      _sWorkerTabService.list(this.PdfSelectedContract[0].PrsID, this.$fun.getUserID()).then(response => {
					this.itemsSearch = response.data[0];
					this.openPdfCritical = true;
          this.$nextTick(() => {
            this.$refs.pdfCriticalPrint?.downloadPdf();

            setTimeout(() => {
              this.openPdfCritical = false;
            }, 300);
          });
				})
    },
    DownloadSSTDocument() {
      const obj = {
        AreID : this.PdfSelectedContract[0].AreID,
        PstID : this.PdfSelectedContract[0].PstID
      }
      _sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
        if(response.data == null){
          this.$fun.alert("No se encontraron resultados de SST","warning");
          return;
        }
        if (response.status == 200) {
          this.itemsSSTCritical = this.PdfSelectedContract[0];
          this.fingerprintImage = null;
          this.itemsst = response.data;
          this.openPdfSSTCritical = true;
          this.$nextTick(() => {
            this.$refs.pdfSSTCritical?.downloadPdf();

            setTimeout(() => {
              this.openPdfSSTCritical = false;
            }, 300);
          });
        }
      })
    },
    PrintSelectedDocuments() {
      // Muestra el modal de carga
      this.dialogloading = true;

      let delay = 0;
      let totalDownloads = 0;  // Contador de descargas
      let completedDownloads = 0;  // Contador de descargas completadas

      // Función para verificar si todas las descargas han terminado
      const checkAllDownloadsCompleted = () => {
        completedDownloads++;
        if (completedDownloads === totalDownloads) {
          // Si todas las descargas han terminado, ocultamos el modal de carga
          this.dialogloading = false;
        }
      };

      // Verifica si "Contrato" está seleccionado y descarga el PDF correspondiente
      if (this.selectedDocuments.includes('contract')) {
        totalDownloads++;  // Aumentamos el contador de descargas
        setTimeout(() => {
          this.DownloadContractDocument();
          checkAllDownloadsCompleted();  // Llamamos a la función para contar la descarga completada
        }, delay);
        delay += 300; // Incrementa el retraso para la siguiente descarga
      }

      // Verifica si "Designación del puesto Crítico" está seleccionado y descarga el PDF correspondiente
      if (this.selectedDocuments.includes('critical')) {
        totalDownloads++;
        setTimeout(() => {
          this.DownloadCriticalDocument();
          checkAllDownloadsCompleted();
        }, delay);
        delay += 300;
      }

      // Verifica si "Recomendaciones SST" está seleccionado y descarga el PDF correspondiente
      if (this.selectedDocuments.includes('sst')) {
        totalDownloads++;
        setTimeout(() => {
          this.DownloadSSTDocument();
          checkAllDownloadsCompleted();
        }, delay);
        delay += 300;
      }
    },
    async mergeAndDownloadPDF() {
      this.dialogloading = true;
      
      try {
        // Array para almacenar los PDFs como ArrayBuffers
        const pdfBuffers = [];
        
        // Obtener los PDFs seleccionados
        if (this.selectedDocuments.includes('contract')) {
          const contractBuffer = await this.getPDFBuffer('contract');
          if (contractBuffer) pdfBuffers.push(contractBuffer);
        }
        
        if (this.selectedDocuments.includes('critical')) {
          const criticalBuffer = await this.getPDFBuffer('critical');
          if (criticalBuffer) pdfBuffers.push(criticalBuffer);
        }
        
        if (this.selectedDocuments.includes('sst')) {
          const sstBuffer = await this.getPDFBuffer('sst');
          if (sstBuffer) pdfBuffers.push(sstBuffer);
        }
        
        // Si no hay PDFs, mostrar mensaje y salir
        if (pdfBuffers.length === 0) {
          this.$fun.alert("No se pudieron generar los documentos", "warning");
          this.dialogloading = false;
          return;
        }
        
        // Combinar PDFs usando pdf-lib
        const mergedPdf = await this.mergePDFs(pdfBuffers);

        // Descargar el PDF combinado
        this.downloadMergedPDF(mergedPdf, `Documentos_${this.PdfSelectedContract[0].NtpFullName}.pdf`);
        
      } catch (error) {
        console.error("Error al combinar PDFs:", error);
        this.$fun.alert("Error al combinar los documentos", "error");
      } finally {
        this.dialogloading = false;
      }
    },
    async mergeAndDownloadPDFs() {
      if(this.selectedDocuments == 0){
        this.$fun.alert("Debe seleccionar al menos una opción", "warning");
        return;
      }
      this.dialog = true;

      this.PdfSelectedContract[0].optionSelected = this.selectedDocuments;

        const response1 = await _sGenParam.search({ PrmName: "RH-FR-039" }, this.$fun.getUserID());
        if (response1.status == 200 && response1.data != null) {
          console.log(response1.data.PrmValue);
          this.PdfSelectedContract[0].definitiveReview = response1.data.PrmValue;
        }

        const response2 = await _sGenParam.search({ PrmName: "ST-FR-062" }, this.$fun.getUserID());
        if (response2.status == 200 && response2.data != null) {
          this.PdfSelectedContract[0].definitiveReviewSST = response2.data.PrmValue;
        }

        this.PdfSelectedContract[0].itemsst = [];
        this.PdfSelectedContract[0].DangerSSTDetail = [];
        this.PdfSelectedContract[0].DangerSSTRecommendation = [];
        
        if(this.selectedDocuments.includes('sst')){
          const obj = {
            AreID : this.PdfSelectedContract[0].AreID,
            PstID : this.PdfSelectedContract[0].PstID
          }

          await _sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
            if(response.data == null){
              this.$fun.alert("No se encontraron resultados de SST","warning");
              return;
            }
            if (response.status == 200) {
              this.PdfSelectedContract[0].itemsst = response.data;
              this.PdfSelectedContract[0].DangerSSTDetail = response.data.DangerSSTDetail || [];
              this.PdfSelectedContract[0].DangerSSTRecommendation = response.data.DangerSSTRecommendation || [];
            }
          })
        }

        // if (typeof item.itemsFunciones === "string") {
        //   try {
        //     item.itemsFunciones = JSON.parse(item.itemsFunciones);
        //   } catch (e) {
        //     console.error("Error al parsear item.itemsFunciones:", e);
        //   }
        // } else if (
        //   typeof item.itemsFunciones !== "object" ||
        //   item.itemsFunciones === null
        // ) {
        //   console.error(
        //     "item.itemsFunciones no es un JSON válido o no es una cadena JSON."
        //   );
        // }

      //this.PdfSelectedContract = items;
      this.dialog = false;
      this.dialogPdfContractMasivo = true;
    },
    closeModalDocument() {
      this.DialogDocument = false;
      this.$emit("close");
    },
    async getPDFBuffer(documentType) {
      // Hacemos visible el componente temporalmente
      switch (documentType) {
        case 'contract': {
          // Determinar qué tipo de contrato es y hacer visible el componente correspondiente
          switch (this.PdfSelectedContract[0].TypeContract) {
            case 11: {
              this.dialogPdfContractPorTemporada = true;
              await this.$nextTick();
              
              // Obtener el PDF como Blob
              const blob = await this.getPDFBlobFromComponent('pdfContractPorTemporada');

              this.dialogPdfContractPorTemporada = false;
              return await this.blobToArrayBuffer(blob);
            }
            case 3: {
              this.dialogPdfContractPorIncrementoActividades = true;
              await this.$nextTick();
              
              // Obtener el PDF como Blob
              const blob = await this.getPDFBlobFromComponent('PdfContractPorIncrementoActividades');
              
              // Convertir Blob a ArrayBuffer
              const arrayBuffer = await this.blobToArrayBuffer(blob);

              this.dialogPdfContractPorIncrementoActividades = false;
              return arrayBuffer;
            }
            case 9: {
              this.dialogPdfContractPorServicioEspecifico = true;
              await this.$nextTick();
              
              // Obtener el PDF como Blob
              const blob = await this.getPDFBlobFromComponent('PdfContractPorServicioEspecifico');
              
              // Convertir Blob a ArrayBuffer
              const arrayBuffer = await this.blobToArrayBuffer(blob);

              this.dialogPdfContractPorServicioEspecifico = false;
              return arrayBuffer;
            }
          }
          // ... más casos según tus tipos de contratos
          break;
        }
        case 'critical': {
          // Para el documento crítico
          // Primero obtenemos los datos necesarios
          try {
            const response = await _sWorkerTabService.list(this.PdfSelectedContract[0].PrsID, this.$fun.getUserID());
            if (response.data == null) {
              this.$fun.alert("No se encontraron resultados de crítico", "warning");
              return null;
            }
            this.itemsSearch = response.data[0];
            this.openPdfCritical = true;
            
            await this.$nextTick();
            const blob = await this.getPDFBlobFromComponent('pdfCriticalPrint');
            this.openPdfCritical = false;
            return await this.blobToArrayBuffer(blob);
          } catch (error) {
            console.error("Error al obtener crítico:", error);
            return null;
          }
        }
        case 'sst': {
          // Para el documento SST
          const obj = {
            AreID: this.PdfSelectedContract[0].AreID,
            PstID: this.PdfSelectedContract[0].PstID
          };
          console.log("obj", obj);
          try {
            const response = await _sDangerSSTService.list(obj, this.$fun.getUserID());
            if (response.data == null) {
              this.$fun.alert("No se encontraron resultados de SST", "warning");
              return null;
            }
            console.log("this.PdfSelectedContract", this.PdfSelectedContract[0]);
            this.itemsSSTCritical = this.PdfSelectedContract[0];
            this.fingerprintImage = null;
            this.itemsst = response.data;
            this.openPdfSSTCritical = true;
            await this.$nextTick();
            const blob = await this.getPDFBlobFromComponent('pdfSSTCritical');
            this.openPdfSSTCritical = false;
            return await this.blobToArrayBuffer(blob);
          } catch (error) {
            console.error("Error al obtener SST:", error);
            return null;
          }
        }
      }
      
      return null;
    },
    async getPDFBlobFromComponent(refName) {
      return new Promise((resolve, reject) => {
        // Modificar el método downloadPdf en tus componentes para que acepte un callback
        // O crear un nuevo método en los componentes que devuelva el Blob en lugar de descargarlo
        this.$refs[refName].getPDFBlob()
          .then(blob => resolve(blob))
          .catch(error => reject(error));
      });
    },

    // Convertir Blob a ArrayBuffer
    async blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },

    // Combinar PDFs usando pdf-lib
    async mergePDFs(pdfBuffers) {
      const mergedPdf = await PDFDocument.create();
      
      for (const pdfBuffer of pdfBuffers) {
        const pdf = await PDFDocument.load(pdfBuffer);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach(page => mergedPdf.addPage(page));
      }
      
      return await mergedPdf.save();
    },

    // Descargar el PDF combinado
    downloadMergedPDF(pdfBytes, filename) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    searchWorkertByNtpID(ntpID){
      _sWorkertService.searchworkertByNtpID(ntpID,this.$fun.getUserID()).then(x=>{
        console.log("DataAAAAAAAAA",x);
        this.workert = x.data;       
      },
      (e) => {
        this.$fun.alert("Ocurrio un error-> " + e.response.data.Message, "error");
      }).catch((error) => {
        console.error("Error ->", error);        
      });
    },
    openDialog(){
      this.dialogEditPerson=true;
    },
    personSaved(event){
      console.log("evento",event);
    },
    closeDialog(){      
      this.dialogEditPerson=false;
    },
    close(){
      this.$fun.alertFull('¿Está seguro de Salir?', 'question')
			.then(resp => {
        if(resp.value){
          this.$emit("close");
        }
      });
    },
    cancelar(){

    },

    // Método para abrir la cámara
    openCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 640, height: 480 } }) // Resolución baja
        .then((stream) => {
          this.$refs.video.srcObject = stream; // Asignar el stream al elemento <video>
          this.$refs.video.style.display = 'block'; // Mostrar el video en la interfaz
          this.$refs.video.play(); // Reproducir video
          this.isCameraActive = true; // Indicar que la cámara está activa
        })
        .catch((err) => {
          console.error("Error al acceder a la cámara: ", err);
          this.isCameraActive = false;
        });
    },

    // Método para capturar la foto desde el video
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      // Asegurarnos que el canvas tiene el mismo tamaño que el video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Dibujar el fotograma actual del video en el canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convertir la imagen a base64
      const dataUrl = canvas.toDataURL("image/jpeg");

      // Asignar la imagen capturada al estado `image`
      this.image = dataUrl;

      // Detener la cámara
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      video.srcObject = null;
      this.isCameraActive = false;

      // Subir la imagen al servidor
      //this.uploadImage(dataUrl);
    },

    // Subir la imagen capturada al servidor
    uploadImage(imageData) {
    if (imageData != null) {
        // Crear un nuevo objeto FormData
        var formData = new FormData();
        
        // Convertir la imagen base64 a un Blob para enviar como archivo
        const byteString = atob(imageData.split(',')[1]); // Obtener los bytes de la imagen en base64
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        
        const file = new Blob([arrayBuffer], { type: 'image/jpeg' });
        
        // Añadir el archivo al FormData
        formData.append('file', file, 'photo.jpg'); // 'photo.jpg' es el nombre del archivo

        // Ahora envía el FormData al backend
        _service.uploadfile(this.dnii, this.$fun.getUserID(), formData, 12)
            .then((resp) => {
                if (resp.status == 200) {
                    this.photoSampling = [];
                    this.$fun.alert("Cargado correctamente", "success");
                    return;
                }
            })
            .catch(error => {
                console.error("Error al subir la imagen: ", error);
            });
    } else {
        this.$fun.alert("Sin archivo para subir");
    }
}
},
created() {
  if (this.objEdit && typeof this.objEdit === 'object') {
    this.item = Object.assign({}, this.objEdit);
  } else {
    this.item = {}; // En caso de que objEdit sea null o undefined
  }
  this.initialize();
  // Proporcionar los datos a los hijos
  provide('contractDates', this.dates);
},
mounted(){
 
}
};
</script>
<style scoped>
.custom-item {
  min-height: 40px; /* Reducir tamaño */
  border-radius: 10px; /* Bordes redondeados */
  transition: background-color 0.2s;
}

.selected-item {
  background-color: rgba(58, 158, 241, 0.2) !important; /* Color de fondo al seleccionar */
  margin-bottom: 3px;
}
</style>