<template>
	<div>
        <v-dialog v-model="showAdd" fullscreen persistent v-if="showAdd">
			<contract-add ref="add"  @close="close()"  />
		</v-dialog>
		<v-dialog v-model="showEdit"    persistent v-if="showEdit">
			<contract-edit ref="edit" :objEdit="item" @close="close()" :extension="extension" :renovation="renovation"/>
		</v-dialog>
		<v-dialog v-model="showView"    persistent v-if="showView">
			<contract-view ref="textEditPerson" :objEdit="item" :personEdit="personEdit" @close="close()" :extension="extension" :renovation="renovation"/>
		</v-dialog>
		<!-- <v-dialog v-model="showView"    persistent v-if="showView" max-width="950px">
			<contract-view
				ref="textEditPerson"
				@personSaved="personSaved($event)"
				@toclose="close()"
				:propPerson="personEdit"            
			/> 
		</v-dialog> -->
			<!-- <contract-edit ref="edit" :objEdit="item" @close="closeEdit()" /> -->
			<!-- <v-card>
				<v-toolbar
					dense
					color="primary"
					height="40"
					dark
					label="Nuevo Contrato">
					<h3>Creación de Contrato</h3>
					<v-spacer></v-spacer> -->
					<!--<div v-if="item">
						<v-btn
							v-if="item.HvsStatus == 1"
							fab
							class="elevation-0 mr-3"
							small
							color="primary"
							dark
							@click="$refs.edit.save()"
							><v-icon>mdi-content-save</v-icon></v-btn
						>
					</div>
					<v-btn
						v-else
						fab
						class="elevation-0 mr-3"
						small
						color="primary"
						dark
						@click="$refs.edit.save()"
						><v-icon>mdi-content-save</v-icon></v-btn
					>-->
					<!-- <v-btn
						fab
						class="elevation-0"
						small
						color="primary"
						dark
						@click="showEdit = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-row>
					<v-col cols="12" sm="12" lg="12">
					 -->
						<!-- <v-row>
							<v-col cols="12" sm="12" lg="12"> -->
							<!-- <ContractEdit
								ref="edit"
								:objEdit="item"
								@close="closeEdit()"
							/> -->
							<!-- </v-col>
						</v-row> -->
				
				<!-- </v-col>
			</v-row>
			</v-card> -->
		<s-crud
		    edit
			title="Registro de Trabajadores"
			:config="config"
			:filter="filter"
            @add="addContract()"
			add-no-function
			add
			@edit="editHvs($event)"
			@save="save($event)"
			ref="gridAgreement"
            @rowSelected="rowSelected($event)"
			searchInput
		>
			<template v-slot:options>
				<v-tooltip bottom="" v-if="item != null ? true : false">
					<template v-slot:activator="{ on }">
						<v-btn
						v-on="on"
						text
						black 
						@click="printContract(item)"
						small
						>
							<v-icon style="font-size:14px;">mdi-printer</v-icon>
						</v-btn>
					</template>
					<span>Ver Contrato</span>
				</v-tooltip>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" small elevation="0" @click="viewContract(item)">
							<v-icon style="font-size: 16px">mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver</span>
				</v-tooltip>
			</template>
			<template v-slot:filter>
				<v-container>
					<v-row>
						
						<v-col cols="12" sm="6" md="3" lg="2">
							<s-date
								label="Fecha Inicio" 
								v-model="filter.AgtBeginDate"
								v-if="!filter.AgtAll"
							></s-date>
							<s-text
								label="Fecha Inicio" 
								v-model="filter.AgtBeginDate" 
								:disabled="filter.AgtAll === 1"
								v-else
							></s-text>
						</v-col>
						<v-col cols="12" sm="6" md="3" lg="2">
							<s-date
								label="Fecha Fin" 
								v-model="filter.AgtEndDate" 
								v-if="!filter.AgtAll"
							></s-date>
							<s-text
								label="Fecha Fin" 
								v-model="filter.AgtEndDate" 
								:disabled="filter.AgtAll === 1"
								v-else
							></s-text>
						</v-col>
						<v-col cols="12" sm="6" md="3" lg="1">
							<v-switch
								label="Todos"
								v-model="filter.AgtAll"
								dense
								hide-details
								:false-value="0"
								:true-value="1"
							/>
						</v-col>
                        <v-col cols="12" sm="6" md="3" lg="2">
							<s-select-definition
								label="Estado"
								:def="1059"
								v-model="filter.AgtStatus"
								clearable
							></s-select-definition>
						</v-col>
                        <v-col cols="12" sm="6" md="3" lg="2">
							<s-select-definition
								label="Cat. Ocupacional"
								:def="1039"
                                :dgr="4"
								v-model="filter.TypeOccupationalCategory"
								clearable
							></s-select-definition>
						</v-col>
						<v-col cols="12" sm="6" md="3">
							<s-select-area 	label="Area" v-model="filter.AreID"  ></s-select-area>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<!-- <template v-slot:options>
				
			</template> -->

			<template v-slot:PstIsBascCritic="{ row }">
              <v-chip
                x-small
                :color="
                  row.PstIsBascCritic == 1 || row.PstIsBascCritic == 0
                    ? 'white'
                    : row.PstIsBascCritic == 2
                    ? 'primary'
                    : row.PstIsBascCritic == 3
                    ? 'warning'
                    : 'error'
                "
                style="margin:0px;"
                >{{
                  row.PstIsBascCritic == 0
                    ? "No Definido"
                    : row.PstIsBascCriticName
                }}
              </v-chip>
            </template>

			<template v-slot:Status="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.CtrStatus==1?'success':'warning'"
				>
					{{ row.HmnEstado }}
				</v-chip>
			</template>
			<template v-slot:DisProDep="{ row }">
			
				{{ row.DisName }} - {{ row.ProName }} - {{ row.DepName }}
			
			</template>
			<template v-slot:Action="{ row }">
				<v-btn
					x-small
					color="info"
					@click="clickActionTxt(row)"
				>
				<v-icon>mdi-file-document</v-icon>{{'txt'}}
				</v-btn>

			</template>
			
			<template v-slot:AgtStatus="{ row }">
				<v-chip
					x-small
					:color="row.AgtStatus == 1 ? 'info' : 'error'"
				>
					{{ row.AgtStatus == 1 ? 'Activo' : 'Inactivo' }}
				</v-chip>
			</template>

			<template v-slot:WkrAdmonition="{ row }">
				<v-chip
					x-small
					:color="row.WkrAdmonition != null ? 'error' : 'info'"
				>
					{{ row.WkrAdmonition != null ? 'Si' : 'No' }}
				</v-chip>
			</template>

			<template v-slot:AsgCheck="{ row }">
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn
						v-on="on"
						text
						black 
						color="success"
						@click="rowSelectedCheck(row)"
						x-small
						>
							<v-icon style="font-size:14px;">mdi-file-plus</v-icon>
						</v-btn>
					</template>
					<span>Agregar a Impresión Masiva</span>
				</v-tooltip>
			</template>

			<template v-slot:PstCritical="{ row }">
				<v-menu offset-y v-if="row.PstIsBascCritic >= 3 || row.PstIsBascCriticSST >= 3">
					<template v-slot:activator="{ on, attrs }">
						<v-btn 
						v-bind="attrs"
						v-on="on"
						icon
						x-small
						color="red"
						>
							<v-icon>mdi-file-multiple</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item 
						@click="rowSelectedPstCritical(row)"
						>
							<v-list-item-icon>
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Designación de Puesto Critico</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item 
						@click="rowSelectedPstSSTCritical(row)"
						>
							<v-list-item-icon>
								<v-icon>mdi-file-document</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Imprimir Recomendaciones SST</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>

			<template v-slot:actions="{ row }">
				<v-row>
					<v-col cols="6" sm="6" md="6" lg="6">
						<v-tooltip bottom="" >
							<template v-slot:activator="{ on }">
								<v-btn
									x-small
									fab
									@click="enlargement(row)"
									v-on="on"
									style="text-align: center; align-items: center;"
								>
								<img 
									src="../../assets/smartphone_21934.png" 
									
									style="width: 25px; height: 25px;  "
									/>
									
								</v-btn>
								</template>
							<span>Ampliación</span>
						</v-tooltip> 
					</v-col>
					<v-col cols="6" sm="6" md="6" lg="6">
						<v-tooltip bottom="" >
							<template v-slot:activator="{ on }">
								<v-btn
									x-small
									fab
									@click="renovations(row)"
									v-on="on"
									style="text-align: center;"
								>
								<img 
									src="../../assets/contract_1054754.png" 
									
									style="width: 22px; height: 22px;  " 
									/>
									
								</v-btn>
								</template>
							<span>Renovacion</span>
						</v-tooltip> 
					</v-col>
				</v-row>
				
				
			</template>
				<!-- <span>Ampliación</span>
			</v-tooltip> -->
		</s-crud>

		<br>
		<!-- <v-card>
			<s-toolbar label="IMPRESIÓN MASIVA" dark color="#8e8f91" print @print="printMasiveContract()"></s-toolbar>
			<v-data-table
					item-key="AgtID"
					dense
					:items="itemCheck"
					:headers="headers"
				>
				<template v-slot:[`item.AsgCheckTable`]="{ item }">
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn
							v-on="on"
							text
							black 
							color="#ff0000"
							@click="rowSelectedCheckDelete(item)"
							x-small
							>
								<v-icon style="font-size:14px;">mdi-bookmark-remove</v-icon>
							</v-btn>
						</template>
						<span>Eliminar de Impresión Masiva</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card> -->

		<v-dialog v-if="dialogPdfContractPorTemporada" v-model="dialogPdfContractPorTemporada" fullscreen persistent>
			<PdfContractPorTemporada :data="PdfSelectedContract" @close="dialogPdfContractPorTemporada = false"></PdfContractPorTemporada>
		</v-dialog>
		<v-dialog v-if="dialogPdfContractPorIncrementoActividades" v-model="dialogPdfContractPorIncrementoActividades" fullscreen persistent>
			<PdfContractPorIncrementoActividades :data="PdfSelectedContract" @close="dialogPdfContractPorIncrementoActividades = false"></PdfContractPorIncrementoActividades>
		</v-dialog>
		<v-dialog v-if="dialogPdfContractPorServicioEspecifico" v-model="dialogPdfContractPorServicioEspecifico" fullscreen persistent>
			<PdfContractPorServicioEspecifico :data="PdfSelectedContract" @close="dialogPdfContractPorServicioEspecifico = false"></PdfContractPorServicioEspecifico>
		</v-dialog>
		<v-dialog v-if="dialogPdfContractMasivo" v-model="dialogPdfContractMasivo" fullscreen persistent>
			<PdfContractMasivo :dataMasiva="PdfSelectedContract" @close="dialogPdfContractMasivo = false"></PdfContractMasivo>
            <!-- <button style="position: fixed; bottom: 10px; right: 10px; z-index: 10000;">Hola</button> -->
		</v-dialog>
		<div v-if="dialogPdfContractMasivo" style="position: fixed; bottom: 10px; right: 10px; z-index: 10000; width: 50px; height: 50px;">
			<v-btn x-small fab color="success"><v-icon>mdi-rocket</v-icon></v-btn>
		</div>
		<v-dialog v-if="openPdfCritical" v-model="openPdfCritical" width="unset" persistent>
            <pdf-critical-print
                :itemSelect="itemsSearch"
                @close="openPdfCritical = false">
            </pdf-critical-print>
        </v-dialog>
		<v-dialog v-if="openPdfSSTCritical" v-model="openPdfSSTCritical" width="unset" persistent>
			<div style="position: fixed; bottom: 10px; right: 10px; z-index: 10000; width: 130px; height: 65px;">
				<!-- <v-btn x-small fab color="success"><v-icon>mdi-fingerprint</v-icon></v-btn> -->
				<s-finger-print
				text="Escanear"
				color="success"
				:btnProps="{ 'x-small': true, fab: false }"
				@fingerprintSaved="handleFingerprint">
				</s-finger-print>
			</div>
            <pdf-sst-critical
                :items="itemsSSTCritical"
				:itemsst="itemsst"
				:fingerprintImage="fingerprintImage"
                @close="openPdfSSTCritical = false">
            </pdf-sst-critical>
        </v-dialog>

		<AlertSunshine :dialog="dialog" />
	</div>
</template>

<script>
	import _sContractService 	from '@/services/HumanResource/HmnContractService.js';
	import sTextarea from '@/components/Utils/sTextarea.vue';
    import ContractAdd from "@/views/HumanResource/ContractAdd.vue";
	import ContractEdit from "@/views/HumanResource/ContractEdit.vue";
	import ContractView from "@/views/HumanResource/ContractView.vue";
	import SSelectArea from '@/components/Utils/SSelectArea.vue';
	import Service from "@/services/General/PersonService";
	import _sAdmonition from "@/services/HumanResource/AdmonitionService";
	import sEditPerson from "@/views/HumanResource/AgreementPersonEdit.vue";
	import PdfContractPorTemporada from './PdfContract/PdfContractPorTemporada.vue';
	import PdfContractPorIncrementoActividades from './PdfContract/PdfContractPorIncrementoActividades.vue';
	import PdfContractPorServicioEspecifico from './PdfContract/PdfContractPorServicioEspecifico.vue';
	import PdfContractMasivo from './PdfContract/PdfContractMasivo.vue';
	import _sWorkerTabService from '@/services/HumanResource/HmnWorkerTabService.js';
	import PdfCriticalPrint from './ContractDocument/HmnPrintCritical.vue';
	import PdfSstCritical from './ContractDocument/HmnPrintSSTCritical.vue';
	import _sDangerSSTService from "@/services/HumanResource/HmnDangerSSTService";
	import sFingerPrint from "@/components/Utils/RecursosHm/sFingerPrint";

	export default {
  	components: { 
		sTextarea, 
		ContractAdd, 
		ContractEdit,  
		SSelectArea,
		sEditPerson,
		ContractView,
		PdfContractPorTemporada,
		PdfContractPorIncrementoActividades,
		PdfContractPorServicioEspecifico,
		PdfContractMasivo,
		PdfCriticalPrint,
		PdfSstCritical,
		sFingerPrint
	},
		data() {
			return {
				dialog: false,
				item: [],
				itemCheck: [],
				PdfSelectedContract:{},
				dialogPdfContractPorTemporada: false,
				dialogPdfContractPorIncrementoActividades: false,
				dialogPdfContractPorServicioEspecifico: false,
				dialogPdfContractMasivo: false,
                showEdit: false,
				showAdd : false,
				showView : false,
				typeperson: 1,
				isValidation: 0,
				personEdit: null,
				itemsSearch: [],
				openPdfCritical: false,
				itemsSSTCritical: [],
				openPdfSSTCritical: false,
				itemsst: [],
				fingerprintImage: null,
				filter: {
					AgtAll: 0,
					AgtStatus: 1,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0,
					AreID: null,
					TypeOccupationalCategory:null,
				},
				config: {
					model: {
						AgtID			: "ID",
						NtpBirthDate	: "date",
						Action			: "Action",
                        AgtStatus		: "string",
						DisProDep		: "",
						AgtBeginDate	: "date",
						AgtEndDate		: "date",
						actions 		: "",
						WkrAdmonition	: "string",
						AsgCheck		: "",
						PstCritical	: "",
						PstIsBascCritic : "",
					},
					service: _sContractService,
					headers: [
						// {text: ""									, value: "AsgCheck",   width: 30},
						// {text: "Doc."								, value: "PstCritical",   width: 30},
						{text: "ID"									, value: "AgtID"				},
						// {text: "Acción"                             , value: "Action"				},
						{text: "N° Doc"								, value: "PrsDocumentNumber"	},
						{text: "Apellidos y Nombres Completos"		, value: "NtpFullName"			},
						{text: "Area"								, value: "AreName"				},
						{text: "Cargo"								, value: "PstName"				},
						// {text: "Referencia"						, value: "PadAddressReference"	},
						// {text: "Distrito-Provincia-Departamento"	, value: "DisProDep"		 	},
						{text: "Inicio de Contrato"					, value: "AgtBeginDate"			},
						{text: "Fin de Contrato"					, value: "AgtEndDate"	, width: 30	},
						{text: "Nivel de Criticidad"				, value: "PstIsBascCritic"		},						
						{text: "Estado"								, value: "AgtStatus"	 		},
						{text: "Restricción"						, value: "WkrAdmonition"		},
						{text: "Acciones"							, value: "actions"		 		},
						
						// {text: "Cat. Ocupacional"					, value: "TIPOTRAJADOR"	 },
					]
				},
				extension:false,
				renovation:false,
				AsgCheckTable: "",
				headers: [
					{text: "ID"									, value: "AgtID"				},
					{text: "N° Doc"								, value: "PrsDocumentNumber"	},
					{text: "Nombres y Apellidos Completos"		, value: "NtpFullName"			},
					{text: "Area"								, value: "AreName"				},
					{text: "Cargo"								, value: "PstName"				},
					{text: "Fecha de Inicio de Contrato"		, value: "AgtBeginDate"			},
					{text: "Fecha de Finalización de Contrato"	, value: "AgtEndDate"		 	},
					{text: ""									, value: "AsgCheckTable",  width: 30},
				]
			}
		},
        watch : {
			filter(){
				// console.log("FILTRO",this.filter)
			}
		},
		methods: {
			initialize()
			{
			},

			save(item) {
				
			},
			rowSelected(item) {
				if(item !== null){
					this.item = item[0];
				}else{
					this.item = null;
				}
				
			},
			rowSelectedCheck(item) {
				// console.log("itemCheck:",item);
				if(item !== null){
					const index = this.itemCheck.findIndex(el => el.AgtID === item.AgtID);
					if (index === -1 && item.AgtStatus == 1) {
						this.itemCheck.push(item);
					} else if (index !== -1) {
						// this.itemCheck.splice(index, 1);
						this.$fun.alert("El contrato seleccionado ya se encuentra agregado","warning");
					} else {
						this.$fun.alert("El contrato seleccionado no está activo","warning");
					}
				}					
			},
			rowSelectedPstCritical(item) {
				_sWorkerTabService.list(item.PrsID, this.$fun.getUserID()).then(response => {
					this.itemsSearch = response.data[0];
					this.openPdfCritical = true;
				})
			},
			rowSelectedPstSSTCritical(item) {
				const obj = {
					AreID : item.AreID,
					PstID : item.PstID
				}
				_sDangerSSTService.list(obj, this.$fun.getUserID()).then(response => {
					if(response.data == null){
						this.$fun.alert("No se encontraron resultados de SST","warning");
						return;
					}
					if (response.status == 200) {
						this.itemsSSTCritical = item;
						this.fingerprintImage = null;
						this.itemsst = response.data;
						this.openPdfSSTCritical = true;
					}
				})
			},
			handleFingerprint(image) {
				this.fingerprintImage = image;
			},
			rowSelectedCheckDelete(item) {
				// console.log("itemCheck:",item);
				if(item !== null){
					const index = this.itemCheck.findIndex(el => el.AgtID === item.AgtID);
					if (index !== -1) {
						this.itemCheck.splice(index, 1);
					}
				}					
			},
			clickActionTxt(item){
                // console.log("btnTxt",item);
							if (item) {
					// Crear una cadena de texto con los valores del item separados por '|'
					const textContent = [
						'',
						item.AgtID,
						item.PrsDocumentNumber,
						item.NtpFullName,
						item.NtpBirthDate,
						item.PadAddress,
						item.PadAddressReference,
						item.DisProDep
					].join('|');  // Separar los valores por '|'

					// Crear un Blob (archivo de texto)
					const blob = new Blob([textContent], { type: 'text/plain' });

					// Crear un enlace para descargar el archivo
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = 'contrato.txt'; // Nombre del archivo de texto

					// Simular un clic en el enlace para descargar el archivo
					link.click();
				} else {
					console.log('No hay datos disponibles para descargar');
				}
			},
			addContract(){
				this.showAdd	= true;			
			},

			enlargement(row){
				console.log("row",row);
				_sContractService.workerPerson(row.WkrID,row.AgtID, this.$fun.getUserID())
						.then((r) => {
							if (r.status == 200) {
							
								this.dataWork = r.data;
								//this.item 		= item;
								this.item 		= this.dataWork;
								this.extension	=true;
								this.showEdit 	= true;
								// ServicePer.getNaturalPerson(this.item.DNI, this.$fun.getUserID()).then((r) => {
								// 	if (r.status == 200) {
								// 	console.log("dataPers: ",r.data);
								// 	this.dataPers=r.data;
								// 	console.log("datageneralp: ",this.dataPers);
								// 	console.log("datageneralw: ",this.dataWork);
								
							}
						});
			},

			renovations(row){
				console.log("row",row);
				_sContractService.workerPerson(row.WkrID,row.AgtID, this.$fun.getUserID())
						.then((r) => {
							if (r.status == 200) {
							
								this.dataWork = r.data;
								//this.item 		= item;
								this.item 		= this.dataWork;
								this.renovation	= true;
								this.showEdit 	= true;
								// ServicePer.getNaturalPerson(this.item.DNI, this.$fun.getUserID()).then((r) => {
								// 	if (r.status == 200) {
								// 	console.log("dataPers: ",r.data);
								// 	this.dataPers=r.data;
								// 	console.log("datageneralp: ",this.dataPers);
								// 	console.log("datageneralw: ",this.dataWork);
								
							}
						});	
			},
            editHvs() {
				if(this.item != null){
					if(this.item.AgtStatus != 1){
						this.$fun.alert("No se puede editar un contrato inactivo","warning");
						return;
					}
					//console.log("item1",this.item);
					_sContractService.workerPerson(this.item.WkrID,this.item.AgtID, this.$fun.getUserID())
						.then((r) => {
							if (r.status == 200) {
								
								this.dataWork = r.data;
								//this.item 		= item;
								this.item 		= this.dataWork;
								this.showEdit 	= true;
								// ServicePer.getNaturalPerson(this.item.DNI, this.$fun.getUserID()).then((r) => {
								// 	if (r.status == 200) {
								// 	console.log("dataPers: ",r.data);
								// 	this.dataPers=r.data;
								// 	console.log("datageneralp: ",this.dataPers);
								// 	console.log("datageneralw: ",this.dataWork);
									}
								});
					//this.showEdit 	= true;
					//this.item 		= item;
				}
				else{
					this.$fun.alert("Seleccione un contrato para editar","warning");
				}
			},
			close() {
				this.showEdit 	= false;
				this.showView 	= false;
				this.showAdd	= false;
				this.extension 	= false;
				this.renovation	= false;
				this.$refs.gridAgreement.refresh();
			},
			async printMasiveContract(){
				// console.log("masivo", this.item);
				this.dialog = true;
				const items = [];
				for (const row of this.itemCheck) {
					if(!this.$fun.isAdmin()){
						if(row.TypePosition == 1 || row.TypePosition == 2 || row.TypePosition == 3){
							if(!this.$fun.hasSpecialPermission("Nivel_1")){
								this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal ejecutivo","warning");
								return;
							}
						}
		
						if(row.TypePosition == 4 || row.TypePosition == 5 || row.TypePosition == 6){
							if(!this.$fun.hasSpecialPermission("Nivel_2")){
								this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal administrativo","warning");
								return;
							}
						}
		
						if(row.TypePosition == 7 || row.TypePosition == 8 || row.TypePosition == 9){
							if(!this.$fun.hasSpecialPermission("Nivel_3")){
								this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal operativo","warning");
								return;
							}
						}

						if(!this.$fun.getSecurity().PayrollsAllow.some(payroll => payroll.TypePayroll === row.TypePayroll)){
							this.$fun.alert("Usted no tiene permisos para imprimir este tipo de contrato","warning");
							return;
						}
					}
					// console.log(row);
					await _sContractService.searchContract(row.AgtID, this.$fun.getUserID())
					.then((r) => {
						if (r.status == 200) {
							// console.log("r.data:", r.data);
							const item = r.data[0];
							if (typeof item.itemsFunciones === 'string') {
								try {
									item.itemsFunciones = JSON.parse(item.itemsFunciones);
								} catch (e) {
									console.error("Error al parsear item.itemsFunciones:", e);
								}
							} else if (typeof item.itemsFunciones !== 'object' || item.itemsFunciones === null) {
								console.error("item.itemsFunciones no es un JSON válido o no es una cadena JSON.");
							}
							items.push(item);
						}
					})
				};
				// console.log("items:", items);
				this.PdfSelectedContract = items;
				this.dialog = false;
				this.dialogPdfContractMasivo = true;
			},
			viewContract(value) {
				console.log("value",value);

				if(value == null){
					this.$fun.alert("Seleccione un contrato para visualizar","warning");
				}
				if (value.PrsDocumentNumber == null){
					this.$fun.alert("Seleccione un contrato para visualizar","warning");
					return;
				}
					
				if (value.PrsDocumentNumber.length > 3) {
					let obj = {
						TypePerson: this.typeperson,
						PrsDocumentNumber: value.PrsDocumentNumber,
						UsrID: this.$fun.getUserID(),
						IsValidation: this.isValidation,
					};
					
					Promise.all([
						_sContractService.workerPerson(this.item.WkrID,this.item.AgtID, this.$fun.getUserID()),
						Service.personatusearch(obj, this.$fun.getUserID())
					]).then(([res1, res2]) => {
						if (res1.status == 200) {
							this.dataWork = res1.data;
							this.item 		= this.dataWork;
						}

						if (res2.status == 200) {
							if (res2.data != null && res2.data.StatusPerson != 1) {
								this.personEdit = Object.assign(res2.data, {});
								this.showView = true;
								
								this.$nextTick(() => {
									//console.log("personEdit",this.personEdit);
									this.$refs.textEditPerson.search();
								});
								
								this.$emit("returnPerson", this.personEdit);

								// _sAdmonition.adminitionByNtpID(this.personEdit.NtpID,this.$fun.getUserID()).then((rpta)=>{
								// 	if(rpta.status == 200 && rpta.data){
								// 		this.$fun.alert("ESTA PERSONA TIENE UNA AMONESTACIÓN DEL TIPO -> " + rpta.data.TypeAdmonitionName , "warning");
								// 		this.$emit("returnPerson", null);
								// 		this.nofoundPerson();
								// 		return;
								// 	}
								// });

								if (res2.data.StatusPerson == 2) {
									this.marker = true;
									this.color = "orange darken-2";
								
								} else this.marker = false;
							} else {
								this.$emit("returnPerson", null);
								this.nofoundPerson();
								this.dialogAddPerson=true;
							}
						}
					});
				}
			},
			printContract(row){
				// console.log(row);
				if(!this.$fun.isAdmin()){
					if(row.TypePosition == 1 || row.TypePosition == 2 || row.TypePosition == 3){
						if(!this.$fun.hasSpecialPermission("Nivel_1")){
							this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal ejecutivo","warning");
							return;
						}
					}
	
					if(row.TypePosition == 4 || row.TypePosition == 5 || row.TypePosition == 6){
						if(!this.$fun.hasSpecialPermission("Nivel_2")){
							this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal administrativo","warning");
							return;
						}
					}
	
					if(row.TypePosition == 7 || row.TypePosition == 8 || row.TypePosition == 9){
						if(!this.$fun.hasSpecialPermission("Nivel_3")){
							this.$fun.alert("Usted no tiene permisos para imprimir un contrato de personal operativo","warning");
							return;
						}
					}

					if(!this.$fun.getSecurity().PayrollsAllow.some(payroll => payroll.TypePayroll === row.TypePayroll)){
						this.$fun.alert("Usted no tiene permisos para imprimir este tipo de contrato","warning");
						return;
					}
				}

				_sContractService.searchContract(row.AgtID, this.$fun.getUserID())
				.then((r) => {
					if (r.status == 200) {
						console.log("r.data:", r.data);
						const item = r.data[0];
						if (typeof item.itemsFunciones === 'string') {
							try {
								item.itemsFunciones = JSON.parse(item.itemsFunciones);
							} catch (e) {
								console.error("Error al parsear item.itemsFunciones:", e);
							}
						} else if (typeof item.itemsFunciones !== 'object' || item.itemsFunciones === null) {
							console.error("item.itemsFunciones no es un JSON válido o no es una cadena JSON.");
						}
						this.PdfSelectedContract = item;
						if(item.TypeContract == 11){
							this.dialogPdfContractPorTemporada = true;
						}else if(item.TypeContract == 3){
							this.dialogPdfContractPorIncrementoActividades = true;
						}else if(item.TypeContract == 9){
							this.dialogPdfContractPorServicioEspecifico = true;
						}
					}
				})
			}
		},

		created () {
			//this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			
			this.initialize();
		},
		mounted() {
			this.$fun.hasSpecialPermission("Nivel_1");
			//this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			// console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
.scrollable {
	max-height: 450px; /* Ajusta según sea necesario */
	overflow-y: auto;  /* Habilita el scroll vertical */
}

.v-dialog--fullscreen{
    backdrop-filter: blur(2px);
	background-color: rgba(0, 0, 0, 0.6);
    /* background-color: rgba(33, 33, 33, 0.46); */
}
</style>